import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import Multimedia from '@/dc-it/models/Multimedia'
import MultipleMultimedia from '@/dc-it/types/MultipleMultimedia'
import Select from '@/dc-it/types/Select'

export default class Campaign extends Model {
    endpoint = 'campaigns'

    related = ['multimedia', 'multimedia_en']

    levels = {
      1: 'Primario',
      2: 'Secundario',
    }

    fields = [
      new Field('id', 'Código').disabled(),
      new MultipleMultimedia('multimedia', 'Imagen', Multimedia).hide().setLimit(1),
      new MultipleMultimedia('multimedia_en', 'Imagen en inglés', Multimedia).hide().setLimit(1),
      new Field('title_es', 'Título en español'),
      new Field('title_en', 'Título en inglés'),
      new Field('url_es', 'URL en español').hide(),
      new Field('url_en', 'URL en inglés').hide(),
      new Select('level', 'Nivel', this.levels, '1'),
    ]

    clone = () => Campaign
}
